import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/blog-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Week 5`}</h1>
    <p>{`While we’re saying goodbye to the first month of 2020, I’ll show you another week of fresh releases. This week contains a new split album by DJ Lostboi and Torus. Anunaku is back after a stunning debut ep on Whities. Andras is bringing you a lot of joy on his new album, and last but not least Squarepusher his first album in 6 years is released today.`}<div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=4033309672/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://sitequeeste.bandcamp.com/album/the-flash">
    The Flash by DJ Lostboi &amp; Torus
  </a>
    </iframe>
    <h1>{`DJ Lostboi & Torus - The Flash`}</h1>
    <p>{`DJ Lostboi and Torus split album covers four tracks from each artist. Each composition reveals a discrete chapter of DJ Lostboi and Torus’ journey building to a moment of bittersweet ascension.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://sitequeeste.bandcamp.com/album/the-flash"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=3115369771/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/track=558851121/transparent=true/" seamless>
  <a href="http://3024world.com/album/stargate-ep">Stargate EP by Anunaku</a>
    </iframe>
    <h1>{`Anunaku - Stargate EP`}</h1>
    <p>{`After a stunning debut EP on Whities, Anunaku (aka TSVI) is back with three tracks exploring different parts of the drum spectrum.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://3024world.com/album/stargate-ep"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=773375395/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://andras.bandcamp.com/album/joyful">Joyful by Andras</a>
    </iframe>
    <h1>{`Andras - Joyful`}</h1>
    <p>{`For the past week I’ve had Honeybird and River Red on repeat. Joyful is an invitation to till an old garden under a glistening new light.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://andras.bandcamp.com/album/joyful"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe width="180" height="180" src="https://www.youtube.com/embed/SLreu09_m-s" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h1>{`Squarepusher - Be Up A Hello`}</h1>
    <p>{`Squarepusher his new album is finally out, dive into his vintage hardware with breakbeat mayhem!`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://bleep.com/release/156702-squarepusher-be-up-a-hello"
      }}>{`Bleep`}</a><div style={{
        marginBottom: "80px"
      }} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      